<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Diciembre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por Nombre -->
      <v-text-field class="me-2 filtro" v-model="buscar.nombre" label="Nombre" outlined dense
        hide-details color="primary">
      </v-text-field>

      <!-- Este botón abre un diálogo para agregar una competencia-->
      <v-tooltip color="success" :open-on-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small color="success" v-on="on" v-bind="attrs"
            @mousedown.prevent="dialogoHabilidades = true">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Agregar</span>
      </v-tooltip>
    </section>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header
      :headers="tabla.headers" :footer-props="tabla.footerProps" :items-per-page="tabla.itemsPerPage"
      :page="tabla.page" @update:items-per-page="(i) => tabla.itemsPerPage = i" @update:page="(p) => tabla.page = p"
      :server-items-length="tabla.totalPage" height="64vh" @mouseenter="isMenuOpen = false">
      <!-- Columna del estado -->
      <template v-slot:[`item.estado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>
      <!-- Columna acciones -->
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :color="item.eliminado === false ? 'orange' : 'grey'" icon v-on="on" v-bind="attrs" class="ms-2 me-2"
              @click="item.eliminado === false && abrirDialogoEditar(item)" text>
              <v-icon>border_color</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Editar' : `Habilidad inactiva` }}</span>
        </v-tooltip>

        <v-tooltip location="bottom" :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :color="item.eliminado === false ? 'error' : 'success'" icon class="ms-2 me-2" 
              v-on="on" v-bind="attrs" @click="abrirDialogoEstado(item)" text>
              <v-icon>{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- Diálogo para crear/editar una competencia -->
    <v-dialog v-model="dialogoHabilidades" transition="dialog-bottom-transition" max-width="30rem" persistent :editar="false">
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5 size-card">
          <!-- Formulario para registrar una competencia -->
          <v-form ref="habilidadesForm">
            <!-- Campo para el nombre -->
            <v-text-field class="me-2" v-model="crearHabilidad.nombre" label="Nombre" dense outlined
              :rules="rules.required" color="primary">
            </v-text-field>

            <!-- Botones del formulario -->
            <div class="d-flex justify-end">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" depressed
                @click="!editar ? agregarHabilidad() : editarHabilidad()">Guardar</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ mensajeEstado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";

export default {
  name: "Habilidades",
  data() {
    return {
      buscar: {
        nombre: '',
        estado: null,
      },
      estados: [
        { text: 'Activo', value: false },
        { text: 'Inactivo', value: true }
      ],
      tituloDialogo: 'Agregar',
      dialogoHabilidades: false,
      crearHabilidad: {
        nombre: '',
      },
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "NOMBRE", width: "30%", sortable: false, align: "left", value: "nombre" },
          { text: "ESTADO", width: "10%", sortable: false, align: "center", value: "estado" },
          { text: "ACCIONES", width: "15%", sortable: false, align: "center", value: "acciones" },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      rules: {
        required: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      editar: false,
      habilidadSeleccionada: {},
      usuario: '',
      dialogoCambiarEstado: false,
      mensajeEstado : '',
      sincEnterprise: [1],
    }
  },
  mounted() {
    this.listarHabilidades();
    this.usuario = this.auth.username.toLowerCase();
  },
  watch: {
    'tabla.page': function () {
      this.listarHabilidades();
    },
    'tabla.itemsPerPage': function () {
      this.tabla.page = 1;
      this.listarHabilidades();
    },
    'buscar.nombre': function () {
      this.tabla.page = 1;
      this.listarHabilidades();
    },
    'buscar.estado': function () {
      this.tabla.page = 1;
      this.listarHabilidades();
    },
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    formValid() {
      const { nombre } = this.crearHabilidad;
      return nombre;
    },
  },
  methods: {
    listarHabilidades() {
      this.tabla.loading = true;
      this.$http.get(`msa-hr/api/habilidad/listar`, {
        params: {
          page: this.tabla.page - 1,
          size: this.tabla.itemsPerPage,
          nombre: this.buscar.nombre,
          idEmpresa: this.enterprise.code,
          eliminado: this.buscar.estado
        }
      }).then((response) => {
        this.tabla.items = response.data.content;
        this.tabla.totalPage = response.data.totalElements;
        this.tabla.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.loading = false;
      });
    },
    agregarHabilidad() {
      const habilidad = {
        empresa:{idEmpresa: this.enterprise.code},
        nombre: this.crearHabilidad.nombre,
        eliminado: false,
        createdby: this.usuario,
        lastmodifiedby: this.usuario
      }
      this.$http.post(`msa-hr/api/habilidad/guardar`, habilidad)
        .then(() => {
          this.listarHabilidades();
          this.dialogoHabilidades = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    abrirDialogoEditar(item) {
      this.tituloDialogo = 'Editar';
      this.editar = true;
      this.dialogoHabilidades = true;
      this.crearHabilidad.nombre = item.nombre;
      this.habilidadSeleccionada = item;
    },
    editarHabilidad() {
      const habilidad = {
        idHabilidad:this.habilidadSeleccionada.idHabilidad,
        empresa:{idEmpresa: this.enterprise.code},
        nombre: this.crearHabilidad.nombre,
        eliminado: false,
        createdby: this.usuario,
        lastmodifiedby: this.usuario
      }
      this.$http.post(`msa-hr/api/habilidad/guardar`, habilidad)
        .then(() => {
          this.listarHabilidades();
          this.dialogoHabilidades = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true;
      this.habilidadSeleccionada = item;
      this.mensajeEstado = this.habilidadSeleccionada.eliminado === false ? '¿Desea inactivar la competencia?' : '¿Desea activar la competencia?';
    },
    cambiarEstado() {
      this.habilidadSeleccionada.eliminado = (this.habilidadSeleccionada.eliminado == false) ? true : false;
      const habilidad = {
        idHabilidad:this.habilidadSeleccionada.idHabilidad,
        empresa:{idEmpresa: this.enterprise.code},
        nombre: this.habilidadSeleccionada.nombre,
        eliminado: this.habilidadSeleccionada.eliminado,
        createdby: this.usuario,
        lastmodifiedby: this.usuario
      }
      this.$http.post(`msa-hr/api/habilidad/guardar`, habilidad)
        .then(() => {
          this.listarHabilidades();
          this.dialogoCambiarEstado = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    clear() {
      this.dialogoHabilidades = false;
      this.crearHabilidad.nombre = '';
      this.editar = false;
    },
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

.filtro {
  width: 33.33%;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>